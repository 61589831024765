import css from './Button.module.css';

export const Button = ({
  id = null,
  onClick = null,
  color = 'black',
  children = null,
  fullWidth = false,
  disabled = false,
  size = 'large',
}) => {
  const colorClass =
    color === 'black' ? css.btnBlackContainer :
    color === 'gray' ? css.btnGrayContainer :
    color === 'success' ? css.btnSuccessContainer :
    color === 'error' ? css.btnErrorContainer :
    null;
  const sizeClass =
    size === 'large' ? css.btnLarge :
    size === 'small' ? css.btnSmall :
    size === 'medium' ? css.btnMedium :
    null;
  const fullWidthClass = fullWidth ? css.fullWidth : null;

  return (
    <button
      onClick={onClick}
      className={`${css.btnBaseFont} ${css.btnBaseContainer} ${colorClass} ${fullWidthClass} ${sizeClass}`}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
