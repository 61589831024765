import { camelToLabel } from '../../util/string';
import css from './PublicCampaignStatusChip.module.css';

export const PublicCampaignStatusChip = ({
  status,
}) => {

  let cssClass = css.gray;
  if (['active', 'published'].includes(status)) {
    cssClass = css.green;
  } else if (['completed'].includes(status)) {
    cssClass = css.blue;
  }

  return (
    <div className={`${css.base} ${cssClass}`}>
      {camelToLabel(status)}
    </div>
  )
}

