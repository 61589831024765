import { useEffect, useState } from 'react';
import {
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './PublicCampaignCreatorDetailPage.module.css';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axiosInstance from '../../axios';
import Loading from '../../components/Loading/Loading';
import checkIcon from '../../assets/icons/check-circle-black.svg';
import { types as sdkTypes } from '../../util/sdkLoader';
import { requestCreateListing } from '../Campaigns/CampaignPage.duck';
import toast from 'react-hot-toast';

import bagIcon from '../../assets/icons/bag.svg';
import globeIcon from '../../assets/icons/globe.svg';
import truckIcon from '../../assets/icons/truck.svg';
import cardIcon from '../../assets/icons/card.svg';
import locationIcon from '../../assets/icons/location.svg'
import instagramIcon from '../../assets/icons/instagramColor.svg';
import tiktokIcon from '../../assets/icons/tiktokColor.svg';
import { ConnectStripeDialog } from '../../components/Dialogs/ConnectStripeDialog/ConnectStripeDialog';
import { PublicCampaignDealType } from '../../util/enums';
import { Button } from '../../components/New/Button/Button';
import { AuthDialog } from '../../components/Dialogs/AuthDialog/AuthDialog';

const { Money } = sdkTypes;

const PublicCampaignCreatorDetailPage = ({ currentUser, ...props }) => {
  const { campaignId } = useParams();
  const history = useHistory();
  const userId = currentUser?.id?.uuid;
  const userEmail = currentUser?.attributes?.email;
  const userName = currentUser?.attributes?.profile?.displayName;
  const userAvatar = currentUser?.profileImage ? currentUser.profileImage.attributes.variants['square-small'].url : "-";
  const hasStripeAccount = currentUser?.stripeAccount ? true : false;

  const [campaign, setCampaign] = useState(null);
  const [client, setClient] = useState(null);
  const [applicantStatus, setApplicantStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);
  const [showConnectStripeDialog, setShowConnectStripeDialog] = useState(false);
  const [openAuthDialog, setOpenAuthDialog] = useState(false);

  const dispatch = useDispatch();

  const fetchCampaign = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/public-campaigns/detail/${campaignId}`);
      const { error, message, data } = response.data;

      await fetchUserDetail(data.clientId);

      const applied = data.applicants.find(applicant => applicant.creatorId === userId);

      setCampaign(data);
      setApplicantStatus(applied ? true : false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDetail = async (id) => {
    try {
      const response = await axiosInstance.get(`/sharetribe/user/detail/${id}`);
      const { error, message, data } = response.data;

      if (!error) {
        setClient(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchCampaign();
  }, [campaignId]);

  const onClickApply = async () => {
    try {
      if (!currentUser) {
        setOpenAuthDialog(true);
        return;
      }

      setApplyLoading(true);

      // check if campaign is paid & user has stripe account connected
      if (campaign.dealType === PublicCampaignDealType.PAID && !hasStripeAccount) {
        setShowConnectStripeDialog(true);
        return;
      }

      const response = await axiosInstance.post(`/public-campaigns/applicants/create`, {
        campaignId: campaignId,
        creatorId: userId,
        creatorName: userName,
        creatorEmail: userEmail,
        creatorAvatar: userAvatar,
      });
      const { error, message, data } = response.data;

      if (error) {
        toast.error("Failed to apply to the campaign");
        setApplicantStatus(false);
        return;
      }

      // send apply email
      await axiosInstance.post(`/public-campaigns/applicants/apply/email`, {
        imageUrl: campaign.offerImage,
        campaignName: campaign.name,
        clientName: campaign.clientName,
        creatorName: userName,
        recipientList: [userEmail],
      });

      const applicantId = data.id;

      dispatch(
        requestCreateListing(applicantId, {
          title: campaign.name,
          description: '',
          publicData: {
            usCitizen: 'SG',
            transactionProcessAlias: campaign.price
              ? 'default-purchase/release-1'
              : 'default-inquiry/release-1',
            campaignId: campaign.id,
            listingType: campaign.price ? 'test' : 'seeding',
            unitType: campaign.price ? 'item' : 'inquiry',

            percentDiscount: campaign.percentDiscount,
            priceDiscount: campaign.priceDiscount,
            dealType: campaign.dealType,
          },
          price: campaign.price ? new Money(campaign.price * 100, 'SGD') : new Money(0, 'SGD'),
        })
      );

      if (!error) {
        toast.success('Application submitted successfully');
        setApplicantStatus(true);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setApplyLoading(false);
    }
  };

  const dateOptions = { day: 'numeric', month: 'long' };
  const dateFormatter = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString('en-GB', dateOptions);
  }

  return (
    <>
      <Page title={'Campaign Name'} scrollingDisabled={false}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage={'CaseStudyCreationPage'} />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSideNav
            currentTab={'CaseStudyCreationPage'}
            userProfile={true}
            isAvatar={true}
            currentUser={currentUser}
            isProUser={true}
          />
          <LayoutWrapperMain className={css.wrapperMain}>
            <AuthDialog
              open={openAuthDialog}
              onClose={() => setOpenAuthDialog(false)}
            />
            {loading && <Loading type='animation' />}
            {!loading && campaign != null && (
              <div className={css.content}>
                <Breadcrumb
                  items={[
                    {
                      label: 'Campaigns',
                      onClick: () => history.push('/campaigns'),
                    },
                    {
                      label: `Campaign from ${campaign.clientName}`,
                      onClick: () => history.push(`/campaign/list/${campaign.clientId}`),
                    },
                    {
                      label: `${campaign.name}`,
                    },
                  ]}
                />
                <div className={css.baseContainer}>
                  <div className={css.left}>
                    <div className={css.offerImageContainer}>
                      <img src={campaign?.offerImage} alt="campaign offer image" />
                    </div>
                    <div className={css.cardContainer}>
                      <div className={css.flex16}>
                        <div>
                          <div className={css.offerText}>
                            {campaign?.dealType === 'Gifted' ? (
                              <>
                                Free
                              </>
                            ) : (
                              <>
                                {campaign?.price ? campaign.price : campaign.percentDiscount}{' '}
                                {campaign?.price ? 'SGD' : '%'}
                              </>
                            )}
                          </div>
                          <div className={css.contentText}>
                            {campaign.dealType === 'Gifted' ? 'Free product' : 'Paid campaign'}
                          </div>
                        </div>
                        <div className={css.flex16}>
                          <div className={css.campaignInfoItemContainter}>
                            <div className={css.iconButton}><img src={globeIcon} alt='platform icon' /></div>
                            <div>
                              <div className={css.menuSubtitleText}>Platform</div>
                              <div className={css.contentText}>{campaign.platform}</div>
                            </div>
                          </div>
                          <div className={css.campaignInfoItemContainter}>
                            <div className={css.iconButton}><img src={bagIcon} alt='offer type icon' /></div>
                            <div>
                              <div className={css.menuSubtitleText}>Offer Type</div>
                              <div className={css.contentText}>{campaign.offerType}</div>
                            </div>
                          </div>
                          <div className={css.campaignInfoItemContainter}>
                            <div className={css.iconButton}><img src={['Self Collect', 'Shipment to Creator'].includes(campaign.offerDelivery) ? truckIcon : locationIcon} alt='delivery icon' /></div>
                            <div>
                              <div className={css.menuSubtitleText}>Shipment Method</div>
                              <div className={css.contentText}>{campaign.offerDeliveryLocation ? campaign.offerDeliveryLocation : campaign.offerDelivery}</div>
                            </div>
                          </div>
                          <div className={css.campaignInfoItemContainter}>
                            <div className={css.iconButton}><img src={cardIcon} alt='redemption icon' /></div>
                            <div>
                              <div className={css.menuSubtitleText}>Redemption Method</div>
                              <div className={css.contentText}>{campaign.dealRedemption}</div>
                            </div>
                          </div>
                        </div>
                        {applicantStatus && !loading ? (
                          <Button color='gray' size='medium'>
                            <img src={checkIcon} alt="applied icon" />
                            Applied
                          </Button>
                        ) : (
                          <Button onClick={onClickApply} disabled={applyLoading} size='medium'>
                            Apply {applyLoading && <div><Loading /></div>}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={css.right}>
                    <div className={css.cardContainer}>
                      <div className={css.flex16}>
                        <div>
                          <div className={css.headerContainer}>
                            <div>
                              <div className={css.campaignNameText}>{campaign.name}</div>
                              <div className={css.contentText}>Singapore</div>
                            </div>
                            <div>
                              <div className={css.contentText}>{dateFormatter(campaign.startDate)} - {dateFormatter(campaign.endDate)}</div>
                              {campaign.applicationEndDate && (
                                <div className={css.eventDateText}>Event: {dateFormatter(campaign?.applicationEndDate)}</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={css.flex24}>
                          <div className={css.contentContainer}>
                            <div className={css.subtitleText}>Description</div>
                            <div className={css.contentText}>{campaign.offerDescription}</div>
                          </div>
                          <div className={css.contentContainer}>
                            <div className={css.subtitleText}>Deliverables</div>
                            <div className={css.contentText}>
                              {campaign.deliverables.map((deliverable, index) => (
                                <div className={css.contentText}>{deliverable.title} | {deliverable.videoLengthStart}-{deliverable.videoLengthEnd} sec | {deliverable.videoType}</div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className={css.subtitleText}>Tags</div>
                          <div className={css.tagContainer}>
                            {campaign.creatorNiche.map((tag, index) => (
                              <div className={css.tagItem}>{tag}</div>
                            ))}
                            {campaign.creatorLanguage.map((tag, index) => (
                              <div className={css.tagItem}>{tag}</div>
                            ))}
                            {campaign.creatorGender.map((tag, index) => (
                              <div className={css.tagItem}>{tag}</div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={css.cardContainer}>
                      <div className={css.flex16}>
                        <div className={css.clientProfileContainer}>
                          <div className={css.avatarImageContainer}>
                            <img src={campaign.clientAvatar} alt="client profile image" />
                          </div>
                          <div>
                            <div className={css.menuSubtitleText}>{campaign.clientName}</div>
                            <div>Client</div>
                          </div>
                        </div>
                        <div className={css.contentText}>{client?.attributes.profile.bio}</div>
                        <div className={css.socialMediaGrid}>
                          {client?.attributes.profile.publicData.instagram && (
                            <a href={client.attributes.profile.publicData.instagram} target='_blank' className={css.socialMediaItem}>
                              <div><img src={instagramIcon} alt='instagram icon'/></div>
                            </a>
                          )}
                          {client?.attributes.profile.publicData.tiktok && (
                            <a href={client.attributes.profile.publicData.tiktok} target='_blank' className={css.socialMediaItem}>
                              <div><img src={tiktokIcon} alt='tiktok icon'/></div>
                            </a>
                          )}
                          {client?.attributes.profile.publicData.website && (
                            <a href={client.attributes.profile.publicData.website} target='_blank' className={css.socialMediaItem}>
                              <div><img src={globeIcon} alt='website icon'/></div>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <ConnectStripeDialog
              isOpen={showConnectStripeDialog}
              onClose={() => setShowConnectStripeDialog(false)}
            />
          </LayoutWrapperMain>
        </LayoutSideNavigation>
      </Page>
    </>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

export default compose(connect(mapStateToProps))(PublicCampaignCreatorDetailPage);
