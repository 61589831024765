import React from 'react';
import css from './ClientDealsCard.module.css';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { PublicCampaignStatusChip } from '../Chip/PublicCampaignStatusChip';

const ClientDealsCard = ({
  title,
  brandName,
  startDate,
  endDate,
  productImage,
  statusLabel,
  isCompleted = false,
  isPubliCampaign = false,
  publicCampaignType = null,
  onClick = () => {},
}) => {
  return (
    <div className={css.card} onClick={onClick}>
      <div className={css.image}>
        <img className={`${css.product_image}`} src={productImage} alt="product image" />
      </div>
      <div className={css.description}>
        <div className={css.header}>
          <div className={css.title}>{title}</div>
        </div>
        <>
          <div className={css.brand_text}>{brandName}</div>
          <div className={css.date_text}>
            {dayjs(startDate).format('MMM DD')} - {dayjs(endDate).format('MMM DD')}
          </div>
          <div className={css.statusContainer}>
          {publicCampaignType && (
            <PublicCampaignStatusChip status={publicCampaignType} />
          )}
          {isPubliCampaign ? (
              <PublicCampaignStatusChip status={statusLabel} />
            ) : (
              <div
              className={`${css.status_badge} ${
                isCompleted ? css.status_completed : css.status_active
                }`}
                >
              {statusLabel}
            </div>
          )}
          </div>
        </>
      </div>
    </div>
  );
};

ClientDealsCard.propTypes = {
  productImage: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,

  statusLabel: PropTypes.string,
  isCompleted: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ClientDealsCard;
