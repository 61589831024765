import css from "./AuthDialog.module.css"
import ModalV2Simple from "../../ModalV2/ModalV2Simple"
import { TextField } from "../../New/TextField/TextField"
import { useFormik } from "formik"
import { Button } from "../../New/Button/Button"
import * as Yup from "yup";
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { login } from "../../../ducks/Auth.duck"

export const AuthDialog
= ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Email required!'),
      password: Yup.string().required('Password required!'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      dispatch(login(values.email, values.password, () => onClose()));
    }
  });

  useEffect(() => {
    localStorage.setItem('signup-redirect-creator', window.location.pathname);
  }, []);

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      width={500}
      title={"Sign In to continue"}
    >
      <div className={css.formContainer}>
        <div>
          <span>Email</span>
          <TextField name={'email'} value={formik.values.email} onChange={formik.handleChange} placeholder={'Enter your email'} errorMessage={formik.errors.email}/>
        </div>
        <div>
          <span>Password</span>
          <TextField type="password" name={'password'} value={formik.values.password} onChange={formik.handleChange} placeholder={'Enter your password'} errorMessage={formik.errors.password}/>
        </div>
        <div className={css.passwordRecoveryText}>Forgot your password? <a href="/recover-password">Reset password</a></div>
        <Button size="medium" onClick={formik.handleSubmit}>
          Log In
        </Button>
        <div className={css.signUpText}>Don't have an account? <a href="/signup">Sign Up</a></div>
      </div>
    </ModalV2Simple>
  )
}
