import { useEffect, useState } from 'react';
import {
  LayoutSideNavigation,
  LayoutWrapperAccountSideNav,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './TiktokOffPlatformPage.module.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import axiosInstance from '../../axios';
import userIcon from '../../assets/icons/user.svg';
import { Button } from '../../components/New/Button/Button';
import { TextField } from '../../components/New/TextField/TextField';
import { useFormik } from 'formik';
import classNames from 'classnames';
import Loading from '../../components/Loading/Loading';
import { ArrowPagination } from '../../components/PaginationCollection/ArrowPagination/ArrowPagination';
import ModalV2Simple from '../../components/ModalV2/ModalV2Simple';
import ClientDealsCard from '../../components/DealsCard/ClientDealsCard';
import { TextAreaField } from '../../components/New/TextAreaField/TextAreaField';
import toast from 'react-hot-toast';
import LoadingOverlayScreen from '../../components/LoadingOverlayScreen/LoadingOverlayScreen';
import { countEngagementRate } from '../../util/scrapper';
import { SocialAnalyticDialog } from '../../components/Dialogs/SocialAnalyticDialog/SocialAnalyticDialog';

const TiktokOffPlatformPage = ({ currentUser, ...props }) => {
  // TODO: currentpage is 0 when theres no result, when there result, its still 0, should be minimum 1

  const [overlayLoading, setOverlayLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creators, setCreators] = useState([]);
  const [toInviteList, setToInviteList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openInviteToCampaignDialog, setOpenInviteToCampaignDialog] = useState(false);
  const [timeoutScrapper, setTimeoutScrapper] = useState(false);
  const [timerCountdown, setTimerCountdown] = useState(0);
  const [orderByValue, setOrderByValue] = useState('asc');
  const [orderByField, setOrderByField] = useState('followers');

  //scrap search purpose
  const [tiktokScrapSearchId, setTiktokScrapSearchId] = useState(null);
  const [tiktokScrapCursor, setTiktokScrapCursor] = useState(null);

  const fetchCreators = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/off-platform/tiktok/get', {
        params: {
          socials: formik.values.socials,
          hasEmail: formik.values.hasEmail,
          followerMin: formik.values.followerMin ?? 0,
          followerMax: formik.values.followerMax ?? 0,
          pageSize: 10,
          page,
          orderByField,
          orderByValue,
        }
      });
      const { error, data, meta } = response.data;
      setCreators(data);

      const { totalCount, totalPages, currentPage, pageSize } = meta;
      setTotalPages(totalPages);
      setPage(currentPage < totalPages ? currentPage : totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      socials: '',
      followerMin: 0,
      followerMax: 0,
      hasEmail: true,
    },
  });

  useEffect(() => {
    fetchCreators();
  }, [page]);

  const onClickAddToInvite = async (targetCreator) => {
    const exist = toInviteList.find(creator => creator.id === targetCreator.id);
    if (exist) {
      setToInviteList(toInviteList.filter(creator => creator.id !== targetCreator.id));
    } else {
      setToInviteList([...toInviteList, targetCreator]);
    }
  }

  const onClickSearch = async () => {
    await fetchCreators();
  }

  useEffect(() => {
    if (timerCountdown === 0) {
      setTimeoutScrapper(false);
      return;
    }

    const interval = setInterval(() => {
      setTimerCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timerCountdown]);

  // reset the scraper pagination id when the search keyword changes
  useEffect(() => {
    setTiktokScrapSearchId(null);
    setTiktokScrapCursor(null);
  }, [formik.values.socials])

  const onClickFindMoreAccount = async () => {
    if (formik.values.socials.toString() === '') {
      toast.error('Please enter a socials/username');
      return;
    }
    try {
      setTimeoutScrapper(true);
      setTimerCountdown(15);

      setOverlayLoading(true);
      const response = await axiosInstance.get('https://tiktok-api23.p.rapidapi.com/api/search/account', {
        params: {
          keyword: formik.values.socials,
          cursor: tiktokScrapCursor ?? '0',
          search_id: tiktokScrapSearchId ?? '0',
        },
        headers: {
          'x-rapidapi-key': 'e6470c4fb6mshd165b665d9933b9p1ce744jsn1a46330583ab',
          'x-rapidapi-host': 'tiktok-api23.p.rapidapi.com'
        }
      });
      const { user_list, rid, cursor } = await response.data;

      console.log('searching for: ', formik.values.socials, ' with search id:', tiktokScrapSearchId, 'cursor: ', tiktokScrapCursor);
      console.log('result: ', user_list, rid, cursor);

      setTiktokScrapSearchId(rid);
      setTiktokScrapCursor(cursor);

      const singaporeOnlyAccounts = await Promise.all(user_list.map(async (user) => {
        const { user_info } = user;
        const { nickname, signature, unique_id, follower_count, sec_uid } = user_info;

        const userDetailResponse = await axiosInstance.get('https://tiktok-api23.p.rapidapi.com/api/user/info', {
          params: {
            uniqueId: unique_id,
          },
          headers: {
            'x-rapidapi-key': 'e6470c4fb6mshd165b665d9933b9p1ce744jsn1a46330583ab',
            'x-rapidapi-host': 'tiktok-api23.p.rapidapi.com'
          }
        });
        const { userInfo } = userDetailResponse.data;
        if (userInfo) {
          const { stats, user } = userInfo;
          const { followerCount, followingCount, heart, videoCount, diggCount } =
            stats;
          const { nickname, signature, secUid, region, avatarMedium } = user;

          // if not in Singapore, return
          if (region !== "SG") return null;

          console.log(nickname);

          const url = `https://tiktok-api23.p.rapidapi.com/api/user/posts?secUid=${secUid}&count=35&cursor=0;`;
          const options = {
            method: "GET",
            headers: {
              "x-rapidapi-key":
                "e6470c4fb6mshd165b665d9933b9p1ce744jsn1a46330583ab",
              "x-rapidapi-host": "tiktok-api23.p.rapidapi.com",
            },
          };
          const response = await fetch(url, options);
          const result = await response.json();
          const { itemList } = result.data;

          let totalSaveds = 0;
          let totalComments = 0;
          let totalLikes = 0;
          let totalViews = 0;
          let totalShares = 0;
          let totalVideos = itemList?.length ?? 0;

          for (const post of itemList ?? []) {
            const {
              collectCount,
              commentCount,
              diggCount: likesCount,
              playCount,
              shareCount,
            } = post.stats;

            totalSaveds += collectCount;
            totalComments += commentCount;
            totalLikes += likesCount;
            totalViews += playCount;
            totalShares += shareCount;
          }

          return {
            nickname,
            username: unique_id,
            tiktokSecUid: secUid,
            image: avatarMedium,
            bio: signature,
            country: region,
            followers: followerCount,
            likes: heart,
            posts: videoCount,
            engagementSaves: totalSaveds,
            engagementComments: totalComments,
            engagementLikes: totalLikes,
            engagementViews: totalViews,
            engagementShares: totalShares,
            engagementPosts: totalVideos,
          };
        }
      }));

      const filteredAccounts = singaporeOnlyAccounts.filter(account => account);
      console.log(filteredAccounts, filteredAccounts.length + ' accounts found');

      if (filteredAccounts.length === 0) {
        toast.error('No accounts found, please use another keyword');
        return;
      }

      const newCreators = await axiosInstance.post('/off-platform/tiktok/create/many', {
        creators: filteredAccounts,
      });
      const { error, messsage } = newCreators.data;
      if (!error) {
        console.log(messsage);
      }

      setCreators((prev) => [...prev, ...filteredAccounts]);
    } catch (error) {
      console.error(error);
    } finally {
      setOverlayLoading(false);
    }
  }

  const [openSocialAnalyticDialog, setOpenSocialAnalyticDialog] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const onClickOpenSocialAnalyticDialog = (creator) => {
    setSelectedCreator(creator);
    setOpenSocialAnalyticDialog(true);
  }

  return (
    <>
      <Page title={'Tiktok Off-Platform Search'} scrollingDisabled={false}>
        <LayoutSideNavigation>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage={'TiktokOffPlatformSearchPage'} />
          </LayoutWrapperTopbar>
          <LayoutWrapperAccountSideNav
            currentTab={'TiktokOffPlatformSearchPage'}
            userProfile={true}
            isAvatar={true}
            currentUser={currentUser}
            isProUser={true}
          />
          <LayoutWrapperMain className={css.wrapperMain}>
            <LoadingOverlayScreen isLoading={overlayLoading} />
            <div className={css.title}>Tiktok Off Platform Creators</div>
            <div className={css.formContainer}>
              <div className={css.searchContainer}>
                <div>
                  <>Socials / Username</>
                  <TextField value={formik.values.socials} onChange={formik.handleChange} name={'socials'} placeholder={'Socials'} />
                </div>
                <div>
                  <>Mininum Followers</>
                  <TextField value={formik.values.followerMin} onChange={formik.handleChange} name={'followerMin'} placeholder={'0'} />
                </div>
                <div>
                  <>Maximum Followers</>
                  <TextField value={formik.values.followerMax} onChange={formik.handleChange} name={'followerMax'} placeholder={'0'} />
                </div>
                <div>
                  <>With Email</>
                  <div>
                    <Switch checked={formik.values.hasEmail} onChange={() => formik.setFieldValue('hasEmail', !formik.values.hasEmail)} />
                  </div>
                </div>
                <div className={css.searchButtonContainer}>
                  <Button size='medium' color='black' onClick={onClickSearch}>Search</Button>
                </div>
              </div>
            </div>
            <div className={css.sortContainer}>
              <div>
                <select className={css.inputSelect} onChange={(e) => setOrderByField(e.target.value)} value={orderByField}>
                  <option value='followers'>Followers</option>
                  <option value='engagementRate'>Engagement Rate</option>
                </select>
              </div>
              <div>
                <select className={css.inputSelect} onChange={(e) => setOrderByValue(e.target.value)} value={orderByValue}>
                  <option value='asc'>A-Z</option>
                  <option value='desc'>Z-A</option>
                </select>
              </div>
            </div>
            {loading ?
              <Loading type='animation' />
              :
              <>
                <div className={css.contentContainer}>
                  <table className={css.table}>
                    <thead>
                      <tr>
                        <th>Avatar & Name</th>
                        <th>Username</th>
                        <th>Followers</th>
                        <th>Eng. Rate</th>
                        <th>Growth Rate</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {creators.map(creator => {
                        return (
                          <tr key={creator.id}>
                            <td className={css.avatarContainer}>
                              <img src={creator.image ?? userIcon} alt={creator.name} className={css.avatar} />
                              <span>{creator.nickname}</span>
                            </td>
                            <td>{creator.username}</td>
                            <td>{creator.followers.toLocaleString() ?? 0}</td>
                            <td>{creator.engagementRate ? `${creator.engagementRate} %` : creator.engagementLikes ? `${countEngagementRate(creator.engagementComments, creator.engagementLikes, creator.engagementSaves, creator.engagementShares, creator.engagementViews).toFixed(2)} %` : 'NA'}</td>
                            <td>{creator.growthRate ?? 0} %</td>
                            <td>
                              <div className={css.actionButtonContainer}>
                                <Button size='small' color='black' onClick={() => window.open(`https://www.tiktok.com/@${creator.username}`)}>Visit TikTok</Button>
                                <Button size='small' color={creator.email ? toInviteList.find(c => c.id === creator.id) ? 'gray' : 'black' : 'gray'} disabled={!creator.email} onClick={() => onClickAddToInvite(creator)}>
                                  {toInviteList.find(c => c.id === creator.id) ? 'Remove from list' : 'Invite to Campaign'}
                                </Button>
                                <Button size='small' color='black' onClick={() => onClickOpenSocialAnalyticDialog(creator)}>Analytics</Button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                      {page === totalPages && (
                        <tr>
                          <td colSpan={6}>
                            <Button fullWidth onClick={onClickFindMoreAccount} disabled={timeoutScrapper}>
                              {timeoutScrapper ? `Please wait for ${timerCountdown} seconds before searching again` : 'Find More Accounts'}
                            </Button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {totalPages > 0 &&
                  <div className={css.paginationContainer}>
                    <ArrowPagination
                      currentPage={page}
                      totalPages={totalPages}
                      onPageChange={newCurrentPage => setPage(newCurrentPage)}
                    />
                  </div>
                }
                <div className={css.footerContainer}>
                  <div className={css.creatorsSelectedContainer}>{toInviteList.length} Creators selected</div>
                  <div className={css.inviteToCampaignButtonContainer}>
                    <Button size='medium' color='black' disabled={toInviteList.length <= 0} onClick={() => setOpenInviteToCampaignDialog(true)}>Invite to Campaign</Button>
                  </div>
                </div>
              </>
            }
            <InviteToCampaignDialog
              open={openInviteToCampaignDialog}
              onClose={() => setOpenInviteToCampaignDialog(false)}
              emailList={toInviteList.map(creator => creator.email)}
              userId={currentUser?.id?.uuid}
            />
            <SocialAnalyticDialog
              open={openSocialAnalyticDialog}
              onClose={() => setOpenSocialAnalyticDialog(false)}
              creator={selectedCreator}
            />
          </LayoutWrapperMain>
        </LayoutSideNavigation>
      </Page>
    </>
  );
};

const Switch = ({checked, onChange}) => {
  return (
    <label className={css.switch}>
      <input type="checkbox" checked={checked} onChange={() => onChange()} className={css.switchInput} />
      <span className={classNames(css.slider, css.round)}></span>
    </label>
  );
}

const InviteToCampaignDialog = ({ open, onClose, emailList, userId }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [searchKey, setSearchKey] = useState('');

  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');

  const fetchPublicCampaigns = async () => {
    try {
      const response = await axiosInstance.get(`/campaigns/client/list/${userId}`, {
        params: {
          status: 'active',
        }
      });
      const { error, message, data } = await response.data;
      if (!error) {
        setCampaigns(data);
      }else{
        console.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    if (userId) {
      fetchPublicCampaigns();
    }
  }, [userId]);

  const onClickInvite = async () => {
    try {
      const result = await axiosInstance.post('/off-platform/tiktok/invite/email', {
        recipientList: ['nick@collabreate.co', 'calvin@collabreate.co'],
        campaignId: selectedCampaignId,
        emailSubject,
        emailBody,
      })
      const { error, message } = result.data;
      if (!error) {
        toast.success(message);
        onClose();
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (!userId) return null;

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      title={'Invite to Campaign'}
    >
      <div style={{ marginBottom: '12px' }}>
        You're about to invite {emailList.length} creators to the {campaigns.find((campaign) => campaign.id === selectedCampaignId)?.name ?? ''} campaign.
      </div>
      <div className={css.campaignSelectorFormContainer}>
        <div className={css.flexGrow}>
          <TextField
            value={selectedCampaignId ? campaigns.find((campaign) => campaign.id === selectedCampaignId).name : searchKey} onChange={(e) => setSearchKey(e.target.value)}
            placeholder={'Campaign Title'}
            disabled={selectedCampaignId}
          />
        </div>
        {selectedCampaignId && (
          <Button color='gray' size='medium' onClick={()=> setSelectedCampaignId(null)}>Change Campaign</Button>
        )}
      </div>
      {selectedCampaignId ? (
        <div className={css.emailFormContainer}>
          <TextField placeholder={'Email Subject'} value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)}/>
          <TextAreaField placeholder={'Email Body'} value={emailBody} onChange={(e) => setEmailBody(e.target.value)} height={400} />
        </div>
      ) : (
        <div className={css.campaignListContainer}>
          {campaigns.map(campaign => {
            if (!campaign.name.toLowerCase().includes(searchKey.toLowerCase())) return null;
            return (
              <div className={campaign.id === selectedCampaignId && css.campaignCardWrapper}>
                <ClientDealsCard
                  key={campaign.id}
                  title={campaign.name || '-'}
                  brandName={campaign.clientName}
                  productImage={campaign.offerImage}
                  statusLabel={campaign.status}
                  isCompleted={campaign.status === 'Closed' ? true : false}
                  startDate={campaign.startDate}
                  endDate={campaign.endDate}
                  isPubliCampaign={true}
                  publicCampaignType={campaign.type}
                  onClick={() => setSelectedCampaignId(campaign.id)}
                />
              </div>
            );
          })}
        </div>
      )}
      <Button color='black' onClick={onClickInvite} disabled={!selectedCampaignId}>Send Invites via Email</Button>
    </ModalV2Simple>
  )
}

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

export default compose(connect(mapStateToProps))(TiktokOffPlatformPage);
