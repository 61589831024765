import { countEngagementRate } from "../../../util/scrapper"
import ModalV2Simple from "../../ModalV2/ModalV2Simple"
import css from './SocialAnalyticDialog.module.css'

export const SocialAnalyticDialog = ({
  open,
  onClose,
  creator,
}) => {
  if (!creator) return null;

  const { engagementComments, engagementLikes, engagementSaves, engagementShares, engagementViews, engagementRate } = creator;
  const rate = engagementRate ?? countEngagementRate(engagementComments, engagementLikes, engagementSaves, engagementShares, engagementViews).toFixed(2);

  return (
    <ModalV2Simple
      isOpen={open}
      onClose={onClose}
      width={500}
      title={"Social Analytics"}
    >
      <div className={css.root}>
        <div className={css.headlineContainer}>
          <div>Engagement Rate</div>
          <div className={css.headlineValue}>{rate} %</div>
        </div>
        <div className={css.right}>
          <div>
            <div className={css.labelTitle}>Comments</div>
            <div className={css.labelValue}>{engagementComments ? engagementComments.toLocaleString() : 'N/A'}</div>
          </div>
          <div>
            <div className={css.labelTitle}>Likes</div>
            <div className={css.labelValue}>{engagementLikes ? engagementLikes.toLocaleString() : 'N/A'}</div>
          </div>
          <div>
            <div className={css.labelTitle}>Saves</div>
            <div className={css.labelValue}>{engagementSaves ? engagementSaves.toLocaleString() : 'N/A'}</div>
          </div>
          <div>
            <div className={css.labelTitle}>Shares</div>
            <div className={css.labelValue}>{engagementShares ? engagementShares.toLocaleString() : 'N/A'}</div>
          </div>
          <div>
            <div className={css.labelTitle}>Views</div>
            <div className={css.labelValue}>{engagementViews ? engagementViews.toLocaleString() : 'N/A'}</div>
          </div>
        </div>
      </div>
    </ModalV2Simple>
  )
}
